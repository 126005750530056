<template>
<a :href="link" >
  <font-awesome-icon color="#00a7d8"  :icon="icon" /> 
</a>
</template>

<style scoped>

    a {
        text-decoration: none;
        cursor: pointer;
        color: white;
        
    }

    svg {
        color: #00a7d8 !important;
        
        width: 15px; height: 15px;
        margin: 10px;
        transition: all ease-in 0.3s;
    }

    a svg:hover { 
        width: 19px; height: 19px;
        margin: 8px;
        color: white !important; fill: white;
        
    }


</style>

<script>

// a
  export default {
    props: [ 'link', 'icon' ]
  };
</script>