<template>
  <div>
    <div class='collapse-wrap' ref="myText" :style="[collapsed ? { height : computedHeight } : {}]">
        <slot></slot>
    </div>
  </div>
</template>

<script>
  /** */
export default {
    props: [ 'isOpen' ],
    data() {
      return { 
        collapsed: this.isOpen,
        computedHeight: 0
      }
    },
    methods: {
    initHeight: function(){

        this.$refs['myText'].style.height = 'auto';
        this.$refs['myText'].style.position = 'absolute';
        this.$refs['myText'].style.visibility = 'hidden';
        this.$refs['myText'].style.display = 'block';

        const height = getComputedStyle(this.$refs['myText']).height;     
        this.computedHeight= parseInt(height) * 1.1 + 'px';  

        this.$refs['myText'].style.position = null;
        this.$refs['myText'].style.visibility = null;
        this.$refs['myText'].style.display = null;
        this.$refs['myText'].style.height = 0;

    }
    },
    mounted: function(){
      this.initHeight()
    },
    watch : {
      isOpen (val) {
          this.collapsed = val;
      }
    }
}
</script>

<style>
 .collapse-wrap {
    height: 0;
    overflow: hidden;
    transition: 0.8s;
 }

</style>