<template>
  <div class="menu-wrap">
    <div>
    <div>
        <img src="https://mlx7euyscin8.i.optimole.com/NqjPnlg-MwUstkaj/w:800/h:284/q:mauto/https:/www.mrstudios.eu/wp-content/uploads/2021/02/systemair-mr-intranet.png"/>
        <div class="menu-items"> 
        <a class="menu-item" href="#apps">Apps</a>
        <a class="menu-item" href="#renderings">Renderings</a>
        <a class="menu-item" href="#request">Request</a>
        </div>
    </div>
    <div>
       
    </div>
    </div>
  </div>
</template>

<style scoped>

    .menu-wrap {
        width: 100%;
        height: 120px;
        background-color: #000;
        display: flex;
        flex-direction: row;
        align-items: center; justify-content: center;
    }

    .menu-wrap > div { 
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .menu-wrap > div > div:first-child {
        display: flex; flex-direction: column; justify-content: space-around; align-items: flex-start;
    }

    a {
        text-decoration: none;
        color: white;   
    }
    a.menu-item {
        text-decoration: none;
        padding: 0 10px 0 0;
        color: white;
        cursor: pointer;
        line-height: 1.3em;
        font-size: 13px;
    }

    img { width: 128px; }

    .bttn { font-size: 16px; margin-left: 20px  }


    @media only screen and (min-width: 481px) {

        .menu-wrap > div > div:first-child {  flex-direction: row; align-items: center;   }
        a.menu-item { font-size: 18px; }
        .menu-wrap > div { width: 1200px;  }
        img {  width: 175px;   margin-right: 44px; }
        a.menu-item { text-decoration: none;  padding: 0 40px 0 0; }
        .bttn { font-size: 18px; margin-left: 30px }

    }

</style>
<script>

    import ButtonLink from '@/components/ButtonLink.vue';

  export default {
      components: { ButtonLink }
  };
</script>