import { createApp } from 'vue'
import App from './App.vue'

import HomeView from '@/views/HomeView';
import CallbackView from '@/views/CallbackView';

// auth

import { createRouter, createWebHashHistory } from 'vue-router';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App);

import { library } from "@fortawesome/fontawesome-svg-core";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faWindows } from "@fortawesome/free-brands-svg-icons";

library.add(faDownload);
library.add(faAngleDown);
library.add(faAngleUp);
library.add(faLinkedinIn);
library.add(faEnvelope);
library.add(faGlobe);
library.add(faWindows);
library.add(faBell)


app.component("font-awesome-icon", FontAwesomeIcon);

import { createMetaManager } from 'vue-meta'
app.use(createMetaManager())

import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
app.component('v-select', vSelect)





app.mount('#app');
