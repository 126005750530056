<template>
    <div class="container">
      <div @click="toggle">
        <font-awesome-icon :icon="isOpen ?  'angle-up' : 'angle-down'" />
        <div class="question">{{ question || '' }}</div>
      </div>
       <CollapseWrap :isOpen="isOpen"> 
       <div class="answer" v-html="answer"/>
       </CollapseWrap>
    </div>
</template>

<style scoped>

    .container { padding: 15px 15px 0 15px; text-align: left; }

    .container > div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 15px;
        cursor: pointer;
    }

    svg { 
        width: 15px; height: 15px; margin-right: 10px;
     }

     .question {
         font-weight: 800;
         color: #00a7d8;
         text-decoration: none;
    line-height: 1.3em;
    cursor: pointer;
    font-size: 18px;
     }

     button { color: black }

     .answer {
         font-size: 18px;
         font-weight: 400;
         line-height: 23px;
         padding-bottom: 7px;
         height: max-contents;
         overflow: hidden;
     }



</style>

<script>

import CollapseWrap from '@/components/CollapseWrap.vue';


export default {
    data() {
    return {
      isOpen: false, 
    }
    },
    props: [ 'question', 'answer' ],
    methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      },
    },
    components: { CollapseWrap }
  }

</script>
