<template>
  <div :class="{ hidden: !this.user }">
    
   
              <span class="user-info">
                <img
                  :src="user.picture"
                  alt="User's profile picture"
                  class="nav-user-profile d-inline-block rounded-circle mr-3"
                  width="50"
                />
                <span class="d-inline-block">{{ user.name }}</span>
              </span>
           
  </div>
</template>

<style scoped>
.hidden {
  opacity: 0;
}

img { border-radius: 50%;}

.user-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
   align-items: center;
}

.d-inline-block { margin: 10px}
</style>

<script>
  import { useAuth0 } from '@auth0/auth0-vue';

  export default {
    setup() {
      const {  user } = useAuth0();
      return {
        user
      };
    },
    mounted() {
      const {  user } = useAuth0();
      self.user = user;
    }
  };
</script>