<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `SITE_NAME` }}</template>
  </metainfo>
  <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&family=Roboto&family=Source+Sans+Pro&display=swap" rel="stylesheet">
  <CallbackView />
</template>

<style>
 body {

  color: rgb(239, 239, 239);
  font-family: "source sans pro", "open sans";
}

body { background-color: #050506; margin: 0;}


#app {
  font-family: Source Sans Pro, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

button { margin: 15px; }

nav a {
  font-weight: bold;
}

nav a.router-link-exact-active {
  color: #a8e6ff;
}

btn { 
  background-color: #ccd9df;
}

@media only screen and (min-width: 481px) {
  nav { flex-direction: row; }
}



</style>


<script>

import CallbackView from '@/views/CallbackView.vue'

import { useMeta } from 'vue-meta'

export default {
   setup () {
    useMeta({
      title: 'Systemair 3D portal',
      htmlAttrs: { lang: 'en', amp: true },
      link: [
      { rel: 'icon', href: 'https://mlx7euyscin8.i.optimole.com/NqjPnlg-DCfSlpAb/w:32/h:32/q:mauto/https:/www.mrstudios.eu/wp-content/uploads/2020/12/cropped-MRsymbol-inverted.png'  }
      ]
    })
  },
  components: { CallbackView }
}
</script>

