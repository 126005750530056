<template>
     <div class="faq">
    <FaqItem question="What is MRstudios?" answer="The Systemair Group is the majority shareholder of MRstudios. Based in Prague, Czech Republic, the company is a leading player in the development of Virtual/Augmented/Mixed Reality (VR/AR/MR) applications, 3D product visualisations, animations, and user interfaces." />
    <FaqItem question="What is MRstudios' role within Systemair?" answer="MRstudios:

<br/><br/>● Manages the Systemair Group's global team of 3D artists, also known as the ‘Rendering Team’. This department is responsible for the creation of: animation videos and renderings.

<br/><br/>● Develops all Virtual Reality (VR) and Augmented Reality (AR) applications for the Group, which are primarily used for sales purposes.

<br/><br/>● Educates Systemair staff on the use of these applications." />
    <FaqItem question="Who is responsible for what?" answer="Each new request goes to Raine and Thomas as single points of contact, who then initiate a standardised process.

 

<br/><strong>Raine:</strong>

<br/>● Approves, and objectively prioritises incoming requests according to their sales potential and urgency.

<br/>● Is the bridge between the Systemair Group and MRstudios' development team.

<br/>● Coordinates the Systemair/MRstudios global rendering team.

<br/><strong>Thomas:</strong>

<br/>● Manages the MRstudios development team.

<br/>● Manages the Systemair/MRstudios global rendering team.

<br/>● Communicates with Systemair staff concerning their respective projects." />
<FaqItem question="Where can I access the files developed by MRstudios and the offline rendering team?" answer="All software applications can be downloaded through this portal.<br/><br/> Animation videos and image renderings can be found inside Canto, the Digital Asset Management system of the Systemair Group." />
<FaqItem question="How can I order VR/AR applications, animation movies and offline renderings?" answer="1. Submit your request form through one of the links above.
<br/><br/> 2. Thomas or Raine will get back to you within the next working day.
<br/><br/> 3. Your project is reviewed, approved and prioritised by the MRstudios/Systemair team.
<br/><br/> 4. Provide any missing data.
<br/><br/> 5. Thomas or Raine get back to you outlining the next steps and possible further required data."/>
     </div>
</template>

<style>
    .faq {
        background-color: #050506;
        border-radius: 5px;
        margin-bottom: 40px;
    }
</style>
<script>

import FaqItem from '@/components/FaqItem.vue';

export default {
    components: { FaqItem }
}
</script>