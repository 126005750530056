<template>
  <div class="wrap">
    <div class="bg-img based" />
    <div class="foreground based">
    <h1>Welcome to the Systemair Group’s 3D Portal!</h1>
    <p>Scroll down to find download links, instructions, and additional information related to interactive sales tools, VR/AR software, 3D image renderings and animations by MRstudios for Systemair, Frico, Menerga and the like.</p>
    </div>
  </div>
</template>

<style scoped>

    .wrap {
        display: flex;
        background-color: rgb(37, 37, 37);
        justify-content: center;
        padding: 0 20px 0 20px;
        height: 600px;
    }

    .based {
        width: 100%;
    }

    .bg-img {
        position: absolute;
        left: 0;
        width: 100vw;
        height: 400px;
        background-image: url(https://mlx7euyscin8.i.optimole.com/NqjPnlg-NYv-Rwqv/w:auto/h:auto/q:90/https://www.mrstudios.eu/wp-content/uploads/2020/12/Sys_showroom-Screenshot-2019.07.11-15.48.47.13.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: .09;
    }

    .foreground {
        max-width: calc(100vw - 40px);
        width: 1200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 3;
        background-color: transparent;
    }

    h1 {
        text-align: left;
        width: 100%;
        font-size: 35px;
        font-weight: 600;
        line-height: 1.5em;
    }

    .foreground p {
        margin-top: 0;
        margin-bottom: 0.9rem;
        text-align: left;
        font-size: 18px;
        font-weight: 100;
        line-height: 2em;
    }

    @media only screen and (min-width: 481px) {
        .wrap { padding: 0; height: 400px };

        .based {
         height: 400px !important;
        }
        
    }
</style>

<script>
  
</script>