<template>
<Menu />
<Header />
<h2 id="apps">Interactive VR/AR sales tools</h2>
<div  class="apps-listed">
  <div v-for="(blok, index) in apps" :key="index">
            <AppThumbnail :blok="blok" />
  </div>
</div>
<h2 id="renderings">3D renderings</h2>
<div  class="apps-listed">
  <div v-for="(blok, index) in renderingsList" :key="index">
            <AppThumbnail :blok="blok" />
  </div>
</div>
<div class="footer-area">
  <div class="footer-area-content">
  <div> 
    <div class="first-box">
      <h3 id="request">Request new software <br/> product or rendering</h3>
      <div>Click the button to request a new app or rendering you’d like us to produce. We will contact you to discuss the details of your request and you will then be provided with a quote before the project starts.</div>

<div>Please keep in mind that production time is affected by complexity, resources and other ongoing productions, so your suggested deadline should be at the very least six weeks in the future.</div>
    </div>
    <div class="bttn-container"><ButtonLink><a target="_blank" href="https://eu.jotform.com/form/231761534425050">
    3D content request</a></ButtonLink></div>
    
    
  </div>
  <div class="testemonials-container">
    <Testemonials 
        name="Raine Sorvoja" company="SYSTEMAIR AB" position="Industrial Designer, Group-wide coordinator of VR, AR and 3D projects"
        src="https://mlx7euyscin8.i.optimole.com/NqjPnlg-zDyZHZkF/w:142/h:142/q:90/https://www.mrstudios.eu/wp-content/uploads/2021/02/Raine-Sorvoja_square.png"
        :links="[
        { link: 'https://teams.microsoft.com/l/chat/0/0?users=raine.sorvoja@systemair.com', icon: [ 'fab', 'windows'] },
        { link: 'https://www.linkedin.com/in/raine-sorvoja-93724315/', icon: [ 'fab', 'linkedin-in']},
        { link: 'mailto:raine.sorvoja@systemair.com', icon: ['fas','envelope'] },
        ]"  />
    <Testemonials
        name="Thomas Weiss" company="MRSTUDIOS S.R.O." position="CTO, main Systemair Group expert for VR, AR and 3D projects"
        src="https://mlx7euyscin8.i.optimole.com/NqjPnlg-EzhIjJEd/w:auto/h:auto/q:90/https://www.mrstudios.eu/wp-content/uploads/2020/10/Thomas_pic-650x650-px-1.png"
        :links="[
        { link: 'https://www.mrstudios.eu/', icon: ['fas', 'globe'] },
        { link: 'https://www.linkedin.com/in/thomas-weiss-9b495bba/', icon: ['fab', 'linkedin-in']},
        { link: 'mailto:thomas@mrstudios.eu', icon: ['fas', 'envelope'] },
        ]" />
  </div>
 
  </div>
</div>
</template>
 
<script>

import apps_imported from "../apps.json";

import AppThumbnail from '@/components/AppThumbnail.vue';
import UserProfile from '@/components/UserProfile.vue';
import Header from "@/components/Header.vue";
import ButtonLink from '@/components/ButtonLink.vue';
import Testemonials from '@/components/Testemonials.vue';
import Faq from '@/components/Faq.vue';
import Menu from '@/components/Menu.vue';

export default {
  name: 'callback',
  data() {
            return {
                apps: [],
                user: {},
                renderingsList: [
                  { preview_video: { url: 'https://youtu.be/Saj8oCQzjK0'}, name: 'Product rendering', description: 'Computer-generated, photorealistic image of a product of your choice.', image: 'https://mlx7euyscin8.i.optimole.com/NqjPnlg-tWNz1v3W/w:572/h:321/q:90/https://www.mrstudios.eu/wp-content/uploads/2021/02/prioSilent_45Deg_30Deg.jpg' },
                  { name: 'Product + Environment rendering', description: 'One or more product renderings added to an environment (e.g., a SAVE unit added to a kitchen)', image: 'https://mlx7euyscin8.i.optimole.com/NqjPnlg-ltLpstvK/w:572/h:321/q:90/https://www.mrstudios.eu/wp-content/uploads/2021/02/preview_productAndApplicationRender.jpg' },
                  { name: 'Environment rendering', description: 'Computer-generated, photorealistic image rendering of an environment (e.g., an application area).', image: 'https://mlx7euyscin8.i.optimole.com/NqjPnlg-u6Z5cC-p/w:572/h:321/q:90/https://www.mrstudios.eu/wp-content/uploads/2021/02/preview_ApplicationRender.jpg' },
                ],
            };
  },
  mounted(){
    setTimeout(() => {this.apps = apps_imported.apps}, 500);
  },
   components: {
    AppThumbnail, UserProfile, Header, ButtonLink, Testemonials, Faq, Menu 
  }
}
</script>

<style scoped>
  h2 {
    font-size: 28px;
    font-weight: 400;
    line-height: 60px;
    width: calc(100vw - 40px);
    text-align: left;
    margin: 0 auto;
    padding: 50px 10px 0 10px;

  }

  .apps-listed {
    display: flex;
    flex-direction: column;
    width: 100vw;
    flex-wrap: wrap;
    margin: 0 auto;
    align-items: center;
  }

  .footer-area {
    background-color: #212225;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 90px;
  }

  .footer-area-content {  width: calc(100vw - 40px); padding: 0 20px; }

  .footer-area-content:first-child > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .first-box {
    max-width: 520px;
    text-align: left;
  }

  .first-box h3 {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    line-height: 46px;
    
    padding: 0 20px;
  }

  .first-box div {
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    padding: 20px;
  }

  .bttn-container { width: 80vw; }

  .bttn {
    
    font-size: 14px;
    margin-top: 27px;
    width: 80vw;
    transition: all ease-in 0.2s;
    box-sizing: border-box;
  }



  .testemonials-container {
    display: flex;
    flex-direction: column;
    align-items: stretch ;
    justify-content: space-between;
    margin-top: 70px;
  }

  @media screen and (min-width: 481px){
    
    h2 {
      font-size: 48px;
      padding: 0;
      padding-top: 70px;
      width: 1200px;
      margin-bottom: 20px;
    }

    .apps-listed {
      width: 1200px;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
  }

  .footer-area-content {  width: 1200px; padding: 0; }

  .footer-area-content:first-child > div:first-child {
    flex-direction: row;
  }

  .first-box h3 {
    font-size: 30px;
    padding: 0;
    margin: 50px 0 26px;
  }

  .first-box div { padding: 0}

  .bttn-container { width: 550px; display: flex;  justify-content: center;  }

  .bttn { 
    position: absolute;
    width: 250px; 
    height: 40px;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0px;
    transition: all ease-in 0.2s;
    
    text-align: center;
    padding: 12px 13px 12px;
  }

  .bttn:hover { font-size: 20.5px; width: 335px; transition: all ease-in 0.1s; height: 45px; padding: 14px 17px; margin-top: 0px; }

  .testemonials-container { flex-direction: row; }
    
  }

</style>