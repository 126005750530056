<template>
    <div class="bttn"><slot></slot></div>
</template>

<style>
.bttn {
      height: max-content;
      cursor: pointer;
      font-weight: 700;
      line-height: 1;
      margin: 0;
      padding: 15px;
      width: max-content;
      background-color: rgb(0, 167, 216);
      padding: 12px 13px 12px ;
      color: white;
      font-size: 18px;
      margin-right: 7px;
      display: flex;
      flex-direction: row; justify-content: center;
    }

.bttn a {
    color: white;
    text-decoration: none;
}
</style>

<script>

export default {
    setup() {
        
    },
}
</script>
