<template>
  <div class="container">
    <div>
    <div v-if="blok.preview_video && blok.preview_video.url.length > 0"> 
      <YouTube 
        :src="blok.preview_video.url" 
        ref="youtube"
        :width="this.youtubeWidth"
        :height="this.youtubeHeight"
      />
    </div>
    <img v-else :src='blok.image.filename || blok.image' />
    <div class="name">{{ blok.name }}</div>
    
    <div class="description">{{ blok.description }}</div>
    </div>
    <div class="dropdown-container">
    <ButtonLink v-if="blok.download_ios || blok.download_android || blok.download_windows"  @click="toggleCollapseDownload">
    <div class="overshadow-icon"><font-awesome-icon :icon="collapsed.download ? 'angle-up' : 'download'" /></div> <div class="bttn-name">Download</div>
    </ButtonLink>
    <ButtonLink v-if="blok.report_bug || blok.add_product"  @click="toggleCollapseRequest">
      <div class="overshadow-icon"><font-awesome-icon :icon="collapsed.request ? 'angle-up' : 'bell'" /></div> <div  class="bttn-name">Request</div>
    </ButtonLink>
  </div>
  <div class="dropdown-expanded">
  <CollapseWrap :isOpen="collapsed.download">
        <div class="link-wrap" v-for="(link, index) in links.download" :key="index">
            <a target="_blank" :href="link.url" class="link">{{ link.name }} </a>
        </div>      
  </CollapseWrap>
  <CollapseWrap :isOpen="collapsed.request">
        <div class="link-wrap" v-for="(link, index) in links.request" :key="index">
            <a target="_blank" :href="link.url" class="link">{{ link.name }} </a>
        </div>      
  </CollapseWrap>
  </div>
  </div>
</template>

<script>

import YouTube from 'vue3-youtube'

import ButtonLink from '@/components/ButtonLink.vue';
import CollapseWrap from '@/components/CollapseWrap.vue';



import 'vue-select/dist/vue-select.css';


export default {
  props: {
    blok: {
      type: Object,
    }
  },
  data() {
    let links = { download : [], request: []};

    if(this.blok.download_ios && this.blok.download_ios.url.length > 0) links.download.push({ url: this.blok.download_ios.url, name: "IOS" })
    if(this.blok.download_android && this.blok.download_android.url.length > 0) links.download.push({ url: this.blok.download_android.url, name: "Android" })
    if(this.blok.download_windows && this.blok.download_windows.url.length > 0) links.download.push({ url: this.blok.download_windows.url, name: "Windows" })

    if(this.blok.custom_links && this.blok.custom_links.length > 0){
      this.blok.custom_links.split(';;')
      .forEach(( l ) => { 
         if( l.length < 4 ) return;
         const [ name, url ] = l.split('::');
         links.download.push({ name, url });
       })
    }
    
    if(this.blok.custom_request_links && this.blok.custom_request_links.length > 0){
      this.blok.custom_links.split(';;')
      .forEach(( l ) => { 
         if( l.length < 4 ) return;
         const [ name, url ] = l.split('::');
         links.request.push({ name, url });
       })
    }
    if(this.blok.add_product && this.blok.add_product.url.length > 0) links.request.push({ ...this.blok.add_product, name: "Add New Product" })
    if(this.blok.report_bug && this.blok.report_bug.url.length > 0) links.request.push({ ...this.blok.report_bug, name: "Report Bug" })
    
    const w = Math.min(window.innerWidth, window.outerWidth);
    return { 
      youtubeWidth: w > 480 ? '572' : '100%',
      youtubeHeight: w > 480 ? 330 : 0.6 * (w - 40) ,
      collapsed: {
        download : false,
        request : false,
      },
      links
    };
  },
  methods : {
    toggleCollapseDownload( key )  { 
      this.collapsed.download = !this.collapsed.download;
    },
    toggleCollapseRequest( key )  { 
      this.collapsed.request = !this.collapsed.request;
    },
  },
  components: {
   ButtonLink, CollapseWrap, YouTube
  }
}
</script>

<style scoped>


    .container {
        max-width: calc(100vw - 40px);
        padding: 10px;
        background-color: rgb(33, 34, 36);
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        margin-bottom: 10px;

    }

    .container > div { text-align: left; }

    .btn-link {
      display: flex;
      flex-direction: row;
      align-content: center;
    }

    .btn-link > div { margin-left: 10px; }

    .btn-link > svg { margin-top: -1px; }

    .dropdown-container { 
      display: flex; 
      flex-direction: row;
    }

    .dropdown-expanded {
      display: flex; 
    }

    .dropdown-expanded > div {
      min-width: 150px;
    }

    img {
      width: 100%;
      object-fit: cover;
    }

    .name {
      font-size: 22px;
      letter-spacing: 1px;
      font-weight: 600;
      margin-bottom: 20px;
      margin-top: 16px;
    }

    .description {
      margin-top: 0;
      margin-bottom: 0.9rem;
      font-size: 16px;
      font-weight: 400;line-height: 23.4px;
      min-height: 170px;
    }
 

    .link {
      display: flex;
      flex-direction: row;
      color: rgb(0, 167, 216) !important;
      text-decoration: none;
      font-weight: 700;
      padding: 10px 0 0 12px;
    }

    .link-wrap:last-of-type{
      margin-bottom: 15px;
    }

    .overshadow-icon { margin-right: auto;  }
    .bttn-name { position: absolute; margin-left: 15px;}

    svg, path {
      
      margin-right: 5px;
      margin-left: 0;
      margin-top: -2px;
      width: 20px;
      
    }

    .bttn { margin-bottom: 15px; margin-right: 15px; width: 118px; justify-content: center; }

    .bttn > div:last-child { justify-self: center;}
    

    @media only screen and (min-width: 481px) {

        .container { 
          max-width: 572px;
        }

        img { width: 100%; height: 330px; }

        .name { font-size: 32px; margin-top: 0; }

        .description { font-size: 18px; }
    }

    
</style>
