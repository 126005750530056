<template>
    <div class="container">
        <div class="first">
            <img :src="src || ''" />
            <div>
                <div class="name">{{ name || ""}}</div>
                <div class="company">{{ company || ""}}</div>
            </div>
        </div>
        <div class="position">{{ position || ""}}</div>
        <div class="socials">
            <div>
                <div v-for="(item, index) in links" :key="index">
                <LinkIcon :link="item.link" :icon="item.icon" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.container {
     background-color: #050506;
     border-radius: 5px;
     width: 550px;
     max-width: calc(100vw - 80px);
     color: white;
     display: flex;
     flex-direction: column;
     padding: 12px 20px ;
     margin-bottom: 17px;
}

.first {
    display: flex;
    flex-direction: column;
    align-items: center;
}

img { 
    border-radius:50%;
    margin-right: 22px;
    width: 142px;
    height: 142px;
    object-fit: center;
    filter: grayscale(1);
    }

.name {
    font-size: 35px;
    font-weight: 600;
}

.company {
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 5px;
    text-align: left;
}

.position {
    margin: 15px 0;
    text-align: center;
    font-weight: 50;
}

.socials > div  { 
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 0px;
}

@media screen and (min-width: 481px){
    .first {
    flex-direction: row;
    }

    .position {
    margin: 15px 0;
    text-align: center;
    }
    .socials > div  { 
    float: right;
    justify-content: space-between;
    }
}

</style>

<script>

import LinkIcon from '@/components/LinkIcon.vue';

export default {
    props: [ 'name','company','position','socials', 'src', 'links'],
    components: { LinkIcon }
}
</script>
